<template>
  <div class="page">
    <section id="banner" class="align-middle">
      <banner-image-overlay></banner-image-overlay>
      <h2 class="title">Privacy Policy</h2>
    </section>
    <div class="container 75%">
      <section class="row 200%">
        <p>Your privacy is important to us. This privacy statement explains all
          details regarding the way MySpanishNow ("we", "us" or "our") processes
          your personal data, how and with what purposes.</p>
        <p>We clearly invite you to read all the details in this privacy statement,
          alongside with our
          <router-link :to="{ name: 'TermsAndConditions' }">Terms and Conditions</router-link>,
          before providing your personal data to our website (with domain
          <i>myspanishnow.com</i>).
        </p>
        <p>It's also important to mention that this privacy statement applies only
          to the actions of MySpanishNow and Users with respect to this website.
          It doesn't extend to any websites that could be accessible through
          our website, such as social media websites.
        </p>
        <p>For purposes of the applicable Data Protection Laws, the data controller
          is MySpanishNow, a trade mark with number UK00003516392, class 41 (Teaching
          of Languages)  owned by María Ángeles Bueno Castaño, registered at 2C
          Aberdour Road, Burntisland, Fife, KY3 0EL, Scotland.
        </p>
      </section>
      <section class="row 200%">
        <h3>Data collected by us</h3>
        <p>We may collect the following personal data from you:
        </p>
        <ul>
          <li>Name</li>
          <li>Contact information, such as email addresses or telephone
            numbers
          </li>
        </ul>
        <p>Both of them, in accordance with this privacy policy.</p>
      </section>
      <section class="row 200%">
        <h3>How we collect the data</h3>
        <p>We may collect your data in the following ways:
        </p>
        <ul>
          <li>Providing by you to us</li>
          <li>Providing by third party sources</li>
          <li>Collected automatically</li>
        </ul>
      </section>
      <section class="row 200%">
        <h4>Providing by you to us</h4>
        <p>We can collect data provided by you using the following ways:
        </p>
        <ul>
          <li>When you contact us through our website</li>
          <li>When you register with us and set up an account to receive
            our products/services
          </li>
          <li>When you enter a survey or promotion through our social media
            channel
          </li>
          <li>When you make a payment to us, through our website</li>
          <li>When you choose to receive marketing communications from us</li>
          <li>When you use or buy our products or services</li>
        </ul>
        <p>Each of them in accordance with this privacy policy.</p>
      </section>
      <section class="row 200%">
        <h4>Providing by third party sources</h4>
        <p>We also might receive data about you from the following publicly
          available third party sources:
        </p>
        <ul>
          <li>Facebook</li>
          <li>LinkedIn</li>
          <li>Instagram</li>
          <li>Youtube</li>
          <li>Twitter</li>
        </ul>
      </section>
      <section class="row 200%">
        <h4>Collected automatically</h4>
        <p>When you access our website, we might automatically collect
          data from you (depending on your browser configurations. For more info
          visit <router-link :to="{ name: 'CookiesPolicy' }">Cookies Policy</router-link>).
        </p>
        <p>This collected data can include:</p>
        <ul>
          <li>Your IP address</li>
          <li>Visited time and date</li>
          <li>Pages visited</li>
          <li>The way you interacted with our website, like the links clicked
            and the time spent viewing each content.
          </li>
        </ul>
      </section>
      <section class="row 200%">
        <h3>How we use the data</h3>
        <p>The collected data is used to improve our product/service.
          We use this collected data for:
        </p>
        <ul>
          <li>Website improvements</li>
          <li>Products/services improvements</li>
          <li>Communication by email of marketing materials that might interest you
          </li>
          <li>Contacting via email or telephone for market research purposes.
            Resulting information may be used to update our website
          </li>
        </ul>
        <p>In each case, in accordance with this privacy policy.</p>
        <p>If you are not satisfied, you have the right to object in certain
          circumstances. Visit the <a href="#your-rights">Your Rights</a>
          section for more information.
        </p>
        <h4>Marketing materials</h4>
        <p>For the communication of marketing materials by email to you,
          we will need your consent, whether via a <i>opt-in</i> or
          <i>soft-opt-in</i>:
        </p>
        <ul>
          <li><i>opt-in</i> consent is the type of consent where we are
            required to obtain your explicit consent, that is, you need to take
            positive and affirmative action when consenting by, for example, checking
            a tick box that we will provide
          </li>
          <li><i>soft-opt-in</i> consent is a specific type of consent which applies
            when you have previously engaged with us. For example, if you contact
            us regarding a particular product or service, we will assume that you
            provided your consent (unless you explicitly specified to opt-out)
            to receive marketing materials of similar products and/or services
          </li>
        </ul>
        <p>If you are not happy, <b>you can always withdraw your consent</b>.
          For more information check <a href="#your-rights">Your Rights</a> section.</p>
      </section>
      <section class="row 200%">
        <h3>Who we share data with</h3>
        <p>We may share your data (only when strictly necessary) with the following
          groups or people:
        </p>
        <ul>
          <li>Contractor(s) that needs to perform a security change or an upgrade
            of our website (in accordance with this privacy statement)</li>
          <li>Third party payment providers, who process payments as well as refunds
            over our website
          </li>
          <li>Analytical tools, such as Microsoft Clarity, Google Analytics and
            Facebook Pixel. For more information visit
            <a href="https://privacy.microsoft.com/en-GB/privacystatement"
            target="_blank">
            Microsoft Privacy Policy</a>,
            <a href="https://policies.google.com/privacy"
            target="_blank">
            Google Privacy Policy</a> and
            <a href="https://www.facebook.com/business/m/privacy-and-data"
            target="_blank">
            Facebook Pixel Privacy Policy</a>
          </li>
          <li>Video communication tools, such as Whereby. For more information
            visit <a href="https://whereby.com/information/tos/privacy-policy/"
            target="_blank">
            Whereby Privacy Policy</a>
          </li>
          <li>Email marketing tools, such as MailerLite. For more information visit
            <a href="https://www.mailerlite.com/legal/privacy-policy"
            target="_blank">MailerLite
            Privacy Policy</a>
          </li>
        </ul>
      </section>
      <section class="row 200%">
        <h3>Measures to keep your data secure</h3>
        <p>We will use technical and organisational measures to safeguard your data.
          Such as:
        </p>
        <ul>
          <li>Access to your account is controlled by a password and a user name
            that is unique to you
          </li>
          <li>Secure servers to store your data
          </li>
        </ul>
        <p>Technical and organisational measures include measures to deal with any
          suspected data breach. If you suspect any misuse or loss or unauthorised
          access to your data, please let us know immediately by sending us an email
          to web@myspanishnow.com.
        </p>
        <p>For more detailed information about to protect your information online
          visit <a href="www.getsafeonline.org" target="_blank">Get Safe Online</a>, an organization
          supported by HM Government and leading businesses.
        </p>
      </section>
      <section class="row 200%">
        <h3>Data retention</h3>
        <p>We will hold your data on our systems until you request to delete it.
          However, it may persist on a backup or archival media for legal,
          tax or regulatory purposes.
        </p>
      </section>
      <section id="your-rights" class="row 200%">
        <h3>Your Rights</h3>
        <p>You have the following rights regarding your data:</p>
        <ul>
          <li><b>Right to access</b>, to request copies of the information we
          hold about you at any time, or that we modify, update or delete such
          information. If we provide you with access to the information we
          hold about you, we will not charge you for this, unless your request
          is "manifestly unfounded or excessive". Where we are legally permitted
          to do so, we may refuse your request. If we refuse your request, we
          will tell you the reasons why.</li>
          <li><b>Right to correct</b>, the right to rectify your data if it is
          inaccurate or incomplete</li>
          <li><b>Right to erase</b>, the right to request that we delete or
          remove your data from our systems</li>
          <li><b>Right to restrict our use of your data</b>, the right to "block"
          us from using your data or limit the way in which we can use it</li>
          <li><b>Right to data portability</b>, the right to request that we move,
          copy or transfer your data</li>
          <li><b>Right to object</b>, the right to object to our use of your data
          including where we use it for our legitimate interests</li>
        </ul>
        <p>For enquiries, regarding your rights above, or withdraw your consent
          to the processing of your data, please contact us via this email:
          web@myspanishnow.com
        </p>
        <p>If you are not happy with the way a complaint you make in relation to
          your data is handled by us, you may be able to refer your complaint to
          the relevant data protection authority. For the UK, this is the
          Information Commissioner's Office (ICO). Their contact details can be
          found on <a href="https://ico.org.uk/" target="_blank">ICO</a>.
        </p>
        <p>It is important to keep your data accurate and current. Please keep
          us informed if your data changes during the period for which we hold it.
        </p>
      </section>
      <section class="row 200%">
        <h3>Links to other websites</h3>
        <p>This website may, from time to time, provide links to other websites.
          We have no control over such websites and are not responsible for the
          content of these websites. This privacy policy doesn't extend to your
          use of such websites. You are advised to read the privacy policy or
          statement of other websites prior to using them.
        </p>
      </section>
      <section class="row 200%">
        <h3>Cookies</h3>
        <p>This website places and access certain Cookies on your computer.
          These Cookies are required to improve your experience as well as
          making the website working properly. Nevertheless, we have carefully
          chosen these Cookies to ensure that your privacy is protected and
          respected at all times.
        </p>
        <p>All Cookies used by this website are used in accordance with the
          current UK and EU Cookie Law.
        </p>
        <p>For more information visit our
          <router-link :to="{ name: 'CookiesPolicy' }">Cookies Policy</router-link>
        </p>
      </section>
      <section class="row 200%">
        <h3>General</h3>
        <p>You may not transfer any of your rights under this privacy policy to
          any other person. We may transfer our rights under this privacy policy
          where we reasonably believe your rights will not be affected.
        </p>
        <p>If any court or competent authority finds that any provision of this
          privacy policy (or part of any provision) is invalid, illegal or
          unenforceable, that provision or part-provision will, to the extent
          required, be deemed to be deleted, and the validity and enforceability
          of the other provisions of this privacy policy will not be affected.
        </p>
        <p>Unless otherwise agreed, no delay, act or omission by a party in
          exercising any right or remedy will be deemed a waiver of that, or
          any other, right or remedy.
        </p>
        <p>This Agreement will be governed by and interpreted according to the law
          of Scotland. All disputes arising under the Agreement will be subject
          to the exclusive jurisdiction of the Scottish.
        </p>
      </section>
      <section class="row 200%">
        <h3>Changes to this privacy policy</h3>
        <p>We have the right to change this privacy policy as we may deem necessary
          from time to time or as may be required by law. Any changes will be
          immediately notified on our website and you are deemed to have accepted
          the terms of the privacy policy on your first use of the website following
          the alterations.
        </p>
        <p>You may contact us by further queries via email at web@myspanishnow.com
        </p>
      </section>
    </div>
  </div>
</template>

<script>
import BannerImageOverlay from '../../components/common/utils/BannerImageOverlay.vue';

export default {
  name: 'PrivacyPolicy',
  components: {
    BannerImageOverlay,
  },
};
</script>

<style scoped>
  .container {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .container section:last-child > :last-child {
    margin-bottom: 0;
  }

  section.row > ul {
    padding-left: 5em;
  }

  section.row > h3 {
      padding-left: 3em;
  }

  section.row > h4 {
      padding-left: 3.6em;
  }

  section.row > h5 {
      padding-left: 4.5em;
  }

  section > h3,
  section > h4,
  section > h5,
  section.row > ul {
    width: 100%;
  }

  @media screen and (max-width: 1280px) {
    section.row > h3 {
      padding-left: 2.2em;
    }

    section.row > h4 {
      padding-left: 2.65em;
    }

    section.row > h5 {
      padding-left: 3.3em;
    }
  }

  @media screen and (max-width: 736px) {
    section.row > h3 {
      padding-left: 1.88em;
    }

    section.row > h4 {
      padding-left: 2.25em;
    }

    section.row > h5 {
      padding-left: 2.75em;
    }
  }
</style>
